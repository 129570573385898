<template>
  <div class="c-alpha-signin__otp">
		<section class="c-alpha-signin__otp-content">
      <div class = "c-alpha-signin__otp-content-wrapper">
        <h2 class="c-alpha-signin__otp-content-title">Two-Factor <span class="c-alpha-signin__otp-content-title_blue">Authentication</span></h2>
        <div class="c-alpha-signin__otp-content-form" translate="2fa_placeholder">
          <input autocomplete="off" class="c-alpha-signin__otp-content-form-container-fg-box-input" type="text" name="otp-code" v-model="otpInput" maxlength="6" placeholder="two-factor authentication"  required>
        </div>
        <p class = "c-alpha-signin__otp-content-form-container-fg-error" translate = "2fa_notif_failed" v-if="wrongOtp">{{errMessage}}</p>
        <button type="submit" class="c-alpha-signin__otp-content-form-button" :class="{'c-alpha-signin__otp-content-form-button_disable': !otpChecker}" @click="login">Submit</button>
      </div>
		</section>
	</div>
</template>

<script>
import publicKey from 'raw-loader!../../../public/key/sparrowSecurityAuthPub.key'

export default {
  props:{
    email:String,
    password:String,
    captchaRes:String
  },
  data() {
    return {
      errMessage: 'Default Error Message',
      wrongOtp: false,
      otpInput: '',
      forge: require('node-forge'),
      publicKey:publicKey,
      pubKey:null,
    }
  },
  mounted() {
    this.pubKey = this.forge.pki.publicKeyFromPem(this.publicKey);
  },
  methods: {
    clearOTP() {
      this.otpInput = ""
    },
    encryptInput:function(){
      var inputWithDate = this.input;
      inputWithDate.t = Date.now()
      const enc = this.symEncrypt(inputWithDate);
      const key = this.pubKey.encrypt(enc.key, 'RSA-OAEP', {
        mgf1: {
          md: this.forge.md.sha1.create(),
        },
      });
      const iv = this.pubKey.encrypt(enc.iv, 'RSA-OAEP', {
        mgf1: {
          md: this.forge.md.sha1.create(),
        },
      });
      return {
        ciphertext: this.forge.util.encode64(enc.ciphertext),
        key: this.forge.util.encode64(key),
        iv: this.forge.util.encode64(iv),
      };
    },
    symEncrypt:function(dt){
      const key = this.forge.random.getBytesSync(32);
      const iv = this.forge.random.getBytesSync(16);
      const encCipher = this.forge.aes.createEncryptionCipher(key, 'CBC');
      encCipher.start(iv);
      encCipher.update(this.forge.util.createBuffer(JSON.stringify(dt), 'utf-8'));
      encCipher.finish();
      const ciphertext = encCipher.output.data;
      return {
        key,
        iv,
        ciphertext,
      };
    },
    login:function() {
      if(this.otpChecker) {
        var encryptedInput = this.encryptInput();
        this.$http.post(this.createApiUrlChestNut('users/login-otp?g-recaptcha-response='+ this.captchaRes), encryptedInput).then(response =>{
          this.$store.commit('doLogin',response.body.accessToken)
          this.$http.get(this.createApiUrlChestNut('me')).then(me =>{
            localStorage.setItem('email', me.email);
            localStorage.setItem('ethAmout', me.ethAmout);
            localStorage.setItem('ethAddress', me.ethAddress);
            localStorage.setItem('kycStatus', me.kycStatus);
            localStorage.setItem('amlStatus', me.amlStatus);
            localStorage.setItem('userKYCStatus', me.userKYCStatus);
            localStorage.setItem('addressVerificationStatus', me.addressVerificationStatus);
            localStorage.setItem('vuex', JSON.stringify({'token': response.body.accessToken.token}));
            this.$store.commit("setProfileData", me);

            if (me.body.userKYCStatus === 'UNSUBMITTED') {
                this.$router.push("/registration-process").catch(() => {});
              } else {
                if (localStorage.getItem('apiKeyURL') === null) {
                  if(!me.body.tncAccepted){
                    this.$eventHub.$emit('show-terms')
                  }
                  else{
                    window.location = '/dashboard/#/checker'
                  }
                } else {
                  let url = localStorage.getItem('apiKeyURL')
                  window.location = url
                }
              }

          })
        },err => {
          if(err.body.code == 'DeviceAuthRequired'){
            this.$router.push("/unrecognized").catch(() => {});
          }
          else{
            if(err.body.code == 'IncorrectEmailPassword') {
              this.errMessage = "You have entered an invalid password."
              this.wrongOtp = true;
              this.clearOTP();
            } else {
              if (err.body.code == 'InvalidOTP') {
                this.errMessage = "Invalid authentication code."
                this.wrongOtp = true;
                this.clearOTP();
              }
              if(err.status == 0) {
                this.errMessage = "Too many requests received, please try again shortly."
                this.wrongOtp = true;
                this.clearOTP();
              } else {
                this.errMessage = err.body.message
                this.wrongOtp = true;
                this.clearOTP();
              }
            }
          }
        });
      }
    },
  },
  computed: {
    otpChecker() {
      if(this.otpInput.length < 6) {
        return false
      } else {
        return true
      }
    },
    input: function(){
      return {email:this.email, password: this.password, otp: this.otpInput};
    }
  },
}
</script>
